<template>
	<div class="page">
		<van-nav-bar :title="$t('concubine.concubine')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box" v-if="xuanfeidata && !errorMoney">
			<p class="name">{{ this.xuanfeidata.xuanfei_name }}</p>
			<p class="title">{{ this.xuanfeidata.vo_title }}</p>
			<van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			<van-button round="true" @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">{{$t("concubine.contactMe")}}</van-button>
		</div>

		<van-popup v-model="errorMoney" class="errorMoney" get-container="body"  :close-on-click-overlay="false" :close-on-popstate="true" :lock-scroll="true">
			<div class="header">
			<van-icon class="close" name="cross" @click="back()" />
			</div>
			<div class="content">{{ errorMoneyTxt }}</div>
			<!-- <div class="footer"></div> -->
				</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: null,

			errorMoney: false,
      errorMoneyTxt:'',
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;

				if (res.code == 401) {
					this.errorMoneyTxt = res.msg
					this.errorMoney = true
				}
			});
		},
		yuyue() {
			this.$toast(this.$t("reservation.counselor"));
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style scoped lang="less">
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
}
.name {
	font-size: 1.125rem;
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}

.errorMoney{
width: 80%;
.header{
  // height: 80px;
  // line-height: 80px;
  text-align: center;
  color: #fff;
  padding: 30px;
  background: linear-gradient(90deg, #f905e5, #e6c3a1);
  position: relative;
  .close{
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(0px,-50%);
  }
}
.content{
color: #f905e5;
// height: 200px;
  padding: 30px;
}
}
</style>
